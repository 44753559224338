<template>
    <div>
        <a
            class="tw-mr-6 tw-text-hiorg-rot-500 tw-cursor-pointer"
            @click="openDialog"
        >
            <FontAwesomeIcon
                :icon="icon.icon"
                class="tw-h-4 tw-mr-0.5"
                :class="icon.farbe"
            />
            {{ aktion.text }}
        </a>
        <TransitionRoot
            as="template"
            :show="dialogIsOpen"
            class="hiorg-vue-app"
        >
            <Dialog
                class="tw-relative tw-z-10"
                :initial-focus="initialFocusRef"
                @close="closeDialog"
            >
                <TransitionChild
                    as="template"
                    enter="tw-ease-out tw-duration-300"
                    enter-from="tw-opacity-0"
                    enter-to="tw-opacity-100"
                    leave="tw-ease-in tw-duration-200"
                    leave-from="tw-opacity-100"
                    leave-to="tw-opacity-0"
                >
                    <div class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
                </TransitionChild>

                <div class="tw-fixed tw-inset-0 tw-z-10 tw-w-screen tw-overflow-y-auto">
                    <div
                        class="tw-flex tw-min-h-full tw-items-end tw-justify-center tw-p-4 tw-text-center
                               sm:tw-items-center sm:tw-p-0"
                    >
                        <TransitionChild
                            as="template"
                            enter="tw-ease-out tw-duration-300"
                            enter-from="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                            enter-to="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leave="tw-ease-in tw-duration-200"
                            leave-from="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
                            leave-to="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
                        >
                            <DialogPanel
                                class="tw-relative tw-transform tw-overflow-hidden tw-rounded-lg tw-bg-white tw-px-4 tw-pb-4 tw-pt-5 tw-text-left tw-shadow-xl tw-transition-all sm:tw-my-8 sm:tw-w-full sm:tw-max-w-lg sm:tw-p-6"
                            >
                                <div class="tw-absolute tw-right-0 tw-top-0 tw-pr-4 tw-pt-4">
                                    <button
                                        v-show="!dialogIsProcessing"
                                        type="button"
                                        class="tw-rounded-md tw-bg-white tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-indigo-500 focus:tw-ring-offset-2"
                                        @click="closeDialog"
                                    >
                                        <span class="tw-sr-only">Close</span>
                                        <XIcon
                                            class="tw-h-6 tw-w-6"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    <span
                                        v-show="dialogIsProcessing"
                                        class="tw-flex tw-items-center tw-justify-center"
                                    >
                                        <span
                                            style="border-top-color:transparent"
                                            class="tw-w-6 tw-h-6 tw-border-4 tw-border-hiorg-blau-400 tw-border-dotted tw-rounded-full tw-animate-spin"
                                        />
                                    </span>
                                </div>
                                <div>
                                    <div class="tw-text-center">
                                        <DialogTitle
                                            as="h3"
                                            class="tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900"
                                        >
                                            Weiterleitung zur Umfrage
                                        </DialogTitle>
                                        <div class="tw-mt-2">
                                            <p class="tw-text-sm tw-text-gray-500">
                                                Prima, dass Du HiOrg-Server mitgestalten möchtest.
                                                Für die Umfrage leiten wir Dich auf unser eigenes Chat-System zum Verarbeiten der Antworten weiter.
                                                Es werden keine persönlichen Daten gespeichert.
                                                Falls Du das möchtest, kannst du    im Verlauf des Chats eine Kontakt-E&#8209;Mail-Adresse angeben oder weiter vollständig anonym bleiben.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="tw-mt-5 sm:tw-mt-6">
                                    <button
                                        ref="initialFocus"
                                        type="button"
                                        class="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-bg-red-600 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-white tw-shadow-sm hover:tw-bg-red-500 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-indigo-600 sm:tw-col-start-1"
                                        :disabled="dialogIsProcessing"
                                        @click="openTypebot"
                                    >
                                        Zur Umfrage
                                    </button>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script setup lang="ts">
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import axios, {type RawAxiosRequestHeaders} from "axios";
import {inject, ref, useTemplateRef} from "vue";
import type {InsightAktion} from "../../models/api/insight/InsightAktion";
import {XIcon} from "@heroicons/vue/solid";
import {iconAktionTypeMapper} from "../../utilities/Insight/IconMapper";
import {keyContext} from "../../injection-keys";

const initialFocusRef = useTemplateRef<HTMLElement>("initialFocus");

const props = defineProps<{
    insightId: string;
    aktion: InsightAktion;
}>();
const icon = iconAktionTypeMapper(props.aktion.type);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const context = inject(keyContext)!;

const dialogIsOpen = ref(false);
const dialogIsProcessing = ref(false);

function closeDialog(): void {
    if (dialogIsProcessing.value) {
        return;
    }
    dialogIsOpen.value = false;
}

function openDialog(): void {
    if (dialogIsProcessing.value) {
        return;
    }
    dialogIsOpen.value = true;
}

async function openTypebot(): Promise<void> {
    if (dialogIsProcessing.value) {
        return;
    }
    dialogIsProcessing.value = true;

    const url = new URL(props.aktion.url);
    url.searchParams.set("ov", context.organisation.getKuerzel());

    await axios.post(
        "/ajax/insights_tracking.php",
        {
            "insight-id": props.insightId,
            "aktion": "typebot-opened",
        },
        {
            "headers": {
                "Content-Type": "application/x-www-form-urlencoded",
            } as RawAxiosRequestHeaders,
        },
    );

    window.open(url.toString());
    // eslint-disable-next-line require-atomic-updates
    dialogIsProcessing.value = false;
    closeDialog();
}
</script>
