<template>
    <p
        class="tw-mb-10"
    >
        <span class="tw-font-semibold tw-text-xl">Willkommen in unserem neuen Insights-Bereich!</span><br>
        Hier informieren wir Dich &uuml;ber die neuesten Funktionen, Bugfixes, Unternehmensnews und Aktionen, damit Du
        stets auf dem neuesten Stand bist und das Beste aus HiOrg-Server herausholen kannst.
    </p>
    <div
        v-for="[datum, insightsAll] in insightsMap.entries()"
        :key="datum"
    >
        <InsightCard
            v-for="[kategorie, insightsKategorie] in insightsAll.entries()"
            :key="kategorie"
            :datum="datum"
            :kategorie="kategorie"
            :insights="insightsKategorie"
            class="tw-p-5 tw-bg-white tw-mb-10"
        />
    </div>
</template>

<script setup lang="ts">
import type {Insight} from "../../models/api/insight/Insight";
import InsightCard from "./InsightCard.vue";
import type {KategorieEnum} from "../../models/api/insight/KategorieEnum";
import {insights} from "../../data/Insights/Insights";

const insightsMap = new Map<string, Map<KategorieEnum, Array<Insight>>>();
for (const insight of insights) {
    if (!insightsMap.has(insight.datum)) {
        insightsMap.set(insight.datum, new Map<KategorieEnum, Array<Insight>>());
    }

    const kategorieMap = insightsMap.get(insight.datum)!;
    if (!kategorieMap.has(insight.kategorie)) {
        kategorieMap.set(insight.kategorie, new Array<Insight>());
    }

    kategorieMap.get(insight.kategorie)!.push(insight);
}
</script>
