import {AktionTypeEnum} from "../../../models/api/insight/AktionTypeEnum";
import type {Insight} from "../../../models/api/insight/Insight";
import {InsightTypeEnum} from "../../../models/api/insight/InsightTypeEnum";
import {KategorieEnum} from "../../../models/api/insight/KategorieEnum";
import {LizenzEnum} from "../../../models/api/insight/LizenzEnum";

const insights: Array<Insight> = [
    {
        id: "20240827-Insights",
        datum: "27.08.2024",
        kategorie: KategorieEnum.DESKTOP,
        type: InsightTypeEnum.FEATURE,
        title: "HiOrg-Server Insights",
        lizenz: [LizenzEnum.PRO, LizenzEnum.KURSE, LizenzEnum.FREE],
        content: [
            "HiOrg-Server Insights: Dein zentraler Ort für Updates! Entdecke die neuesten Funktionen, "
            + "erfahre, welche Fehler behoben wurden, und bleibe über Unternehmensneuigkeiten informiert.",
            "Vielleicht hast Du schon die neue Glühbirne oben rechts bemerkt. Wenn sie leuchtet, gibt "
            + "es Neuigkeiten zu entdecken! Ein Klick auf die Lampe führt Dich direkt zur neuen "
            + "Insights-Seite.",
            "HiOrg-Server wird kontinuierlich verbessert und weiterentwickelt und nun kannst Du diese "
            + "Fortschritte transparent nachverfolgen. Du hast Anmerkungen? Großartig! Unser Ziel ist es, Dir "
            + "und anderen Helfenden bestmöglich zu helfen. Dein Feedback unterstützt uns bei der "
            + "Produktentwicklung und trägt so dazu bei, die Software für alle noch besser zu machen 🫶.",
        ],
        aktions: [
            {
                type: AktionTypeEnum.NACHRICHT,
                text: "Nachricht schreiben",
                url: "https://info.hiorg-server.de/kontakt/#kontaktform",
            },
        ],
    },
];

export {
    insights,
};
